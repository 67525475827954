<template>
  <div class="content">
    <div class="row">
      <div class="col-md-8" v-if='!loadingPage'>
        <card>
          <h5 class="title mb-3 text-left">PROFILE</h5>

          <div class="row">
            <div class="col-md-6 pr-md-1 text-left">
              <base-input label="* First Name" v-model="model.firstName" placeholder="First Name" required></base-input>
            </div>
            <div class="col-md-6 pl-md-1 text-left">
              <base-input label="* Last Name" v-model="model.lastName" placeholder="Last Name" required></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 pr-md-1 text-left">
              <base-input label="* Company"   placeholder="Company" v-model="model.company" required></base-input>
            </div>
            <div class="col-md-6 pl-md-1 text-left">
              <base-input label="Email address" v-model="model.email" type="email" placeholder="mike@email.com"></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 text-left">
              <base-input label="* Address" v-model="model.address" placeholder="Home Address" required></base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 pr-md-1 text-left">
              <base-input label="* City" v-model="model.city" placeholder="City" required></base-input>
            </div>
            <div class="col-md-4 px-md-1 text-left">
              <base-input label="* Country" v-model="model.country" placeholder="Country" required></base-input>
            </div>
            <div class="col-md-4 pl-md-1 text-left">
              <base-input label="Postal Code" placeholder="ZIP Code" v-model="model.zip"></base-input>
            </div>
          </div>

          <h5 class="title my-3 text-left">INVOICES</h5>

          <div class="row">
            <div class="col-md-8 text-left">
              <base-input label="Tax Nº" v-model="model.tax" placeholder="Tax Nº"></base-input>
            </div>
            <div class="col-md-4 text-left">
              <label class='control-label'>Default VAT (%)</label>
              <input class='form-control' type='number' v-model='model.vat' name='vat' min='0' />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 text-left">
              <label class='control-label'>Comments/Notes</label>
              <textarea class='form-control px-2' v-model='model.comments' placeholder="Introduce any comment / note. It will appear by default in all invoices" style='border:1px solid rgba(34, 42, 66, 0.4);border-radius:6px;'></textarea>
            </div>
            <div class="col-md-6 text-left">
              <label class='control-label'>Payment Info</label>
              <textarea class='form-control px-2' v-model='model.payment' placeholder="Payment info - It will appear by default in all invoices" style='border:1px solid rgba(34, 42, 66, 0.4);border-radius:6px;'></textarea>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-4 text-left">
              <label class='control-label'>Currency</label>
              <select name='currency' v-model="model.currency" class='form-control' style='border:1px solid rgba(34, 42, 66, 0.4);' @change='changeSymbol()'>
                <option v-for='(currency,index) in currencies' :key='index' :value='currency.cc'>{{currency.name}}</option>
              </select>
            </div>
            <div class="col-md-4 text-left">
              <label class='control-label'>Locale</label>
              <select v-model="model.locale" class='form-control' style='border:1px solid rgba(34, 42, 66, 0.4);'>
                <option :value="undefined">auto</option>
                <option value="de-DE">de-DE</option>
                <option value="fr-FR">fr-FR</option>
                <option value="en-US">en-US</option>
                <option value="en-IN">en-IN</option>
                <option value="es-ES">es-ES</option>
                <option value="pt-PT">pt-PT</option>
                <option value="zh-ZH">zh-ZH</option>
              </select>
            </div>
            <div class="col-md-4 text-left">
              <label class='control-label'>Position</label>
              <br />
              <div style='line-height: 30px;height:35px;text-align:center;'>
                <label><input type='radio' label="Prefix" v-model='model.position' name='position' value='prefix' /> Prefix</label>
                <label><input type='radio' label="Suffix" v-model='model.position' name='position' value='suffix' class='ml-3' /> Suffix</label>
              </div>
            </div>
          </div>

          <template slot="footer">
            <base-button type="light" fill class='float-right' @click='saveCompany()'>Save</base-button>
          </template>
        </card>
      </div>

      <div class="col-md-4" v-if='!loadingPage'>
        <user-card :company="model" v-on:newLogo='saveLogo' style='height:96%;'></user-card>
      </div>

      <template v-if='loadingPage'>
        <div class='col-12' style='text-align:center;'>
          <div class='text-center pt-5 mt-5'>
            <breeding-rhombus-spinner
              :animation-duration="2000"
              :size="65"
              color="#344675"
              style='margin:100px auto;'
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { BreedingRhombusSpinner } from 'epic-spinners'
import { userSession } from '@/userSession'
import BaseButton from '@/components/BaseButton'
import UserCard from './Profile/UserCard.vue'
import {
  Card,
  BaseInput
} from '@/components/index'

var STORAGE_FILE = 'company.json'

export default {
  components: {
    Card,
    UserCard,
    BaseInput,
    BaseButton,
    BreedingRhombusSpinner
  },
  data () {
    return {
      loadingPage: true,
      model: {
        company: '',
        email: '',
        username: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        country: '',
        zip: '',
        logo: '',
        tax: '',
        vat: 0,
        payment: '',
        comments: '',
        currency: 'USD',
        currencySymbol: '$',
        locale: 'en-US',
        position: 'suffix'
      },
      currencies: [
        { 'cc': 'AED', 'symbol': '\u062f.\u0625;', 'name': 'UAE dirham' },
        { 'cc': 'AFN', 'symbol': 'Afs', 'name': 'Afghan afghani' },
        { 'cc': 'ALL', 'symbol': 'L', 'name': 'Albanian lek' },
        { 'cc': 'AMD', 'symbol': 'AMD', 'name': 'Armenian dram' },
        { 'cc': 'ANG', 'symbol': 'NA\u0192', 'name': 'Netherlands Antillean gulden' },
        { 'cc': 'AOA', 'symbol': 'Kz', 'name': 'Angolan kwanza' },
        { 'cc': 'ARS', 'symbol': '$', 'name': 'Argentine peso' },
        { 'cc': 'AUD', 'symbol': '$', 'name': 'Australian dollar' },
        { 'cc': 'AWG', 'symbol': '\u0192', 'name': 'Aruban florin' },
        { 'cc': 'AZN', 'symbol': 'AZN', 'name': 'Azerbaijani manat' },
        { 'cc': 'BAM', 'symbol': 'KM', 'name': 'Bosnia and Herzegovina konvertibilna marka' },
        { 'cc': 'BBD', 'symbol': 'Bds$', 'name': 'Barbadian dollar' },
        { 'cc': 'BDT', 'symbol': '\u09f3', 'name': 'Bangladeshi taka' },
        { 'cc': 'BGN', 'symbol': 'BGN', 'name': 'Bulgarian lev' },
        { 'cc': 'BHD', 'symbol': '.\u062f.\u0628', 'name': 'Bahraini dinar' },
        { 'cc': 'BIF', 'symbol': 'FBu', 'name': 'Burundi franc' },
        { 'cc': 'BMD', 'symbol': 'BD$', 'name': 'Bermudian dollar' },
        { 'cc': 'BND', 'symbol': 'B$', 'name': 'Brunei dollar' },
        { 'cc': 'BOB', 'symbol': 'Bs.', 'name': 'Bolivian boliviano' },
        { 'cc': 'BRL', 'symbol': 'R$', 'name': 'Brazilian real' },
        { 'cc': 'BSD', 'symbol': 'B$', 'name': 'Bahamian dollar' },
        { 'cc': 'BTN', 'symbol': 'Nu.', 'name': 'Bhutanese ngultrum' },
        { 'cc': 'BWP', 'symbol': 'P', 'name': 'Botswana pula' },
        { 'cc': 'BYR', 'symbol': 'Br', 'name': 'Belarusian ruble' },
        { 'cc': 'BZD', 'symbol': 'BZ$', 'name': 'Belize dollar' },
        { 'cc': 'CAD', 'symbol': '$', 'name': 'Canadian dollar' },
        { 'cc': 'CDF', 'symbol': 'F', 'name': 'Congolese franc' },
        { 'cc': 'CHF', 'symbol': 'Fr.', 'name': 'Swiss franc' },
        { 'cc': 'CLP', 'symbol': '$', 'name': 'Chilean peso' },
        { 'cc': 'CNY', 'symbol': '\u00a5', 'name': 'Chinese/Yuan renminbi' },
        { 'cc': 'COP', 'symbol': 'Col$', 'name': 'Colombian peso' },
        { 'cc': 'CRC', 'symbol': '\u20a1', 'name': 'Costa Rican colon' },
        { 'cc': 'CUC', 'symbol': '$', 'name': 'Cuban peso' },
        { 'cc': 'CVE', 'symbol': 'Esc', 'name': 'Cape Verdean escudo' },
        { 'cc': 'CZK', 'symbol': 'K\u010d', 'name': 'Czech koruna' },
        { 'cc': 'DJF', 'symbol': 'Fdj', 'name': 'Djiboutian franc' },
        { 'cc': 'DKK', 'symbol': 'Kr', 'name': 'Danish krone' },
        { 'cc': 'DOP', 'symbol': 'RD$', 'name': 'Dominican peso' },
        { 'cc': 'DZD', 'symbol': '\u062f.\u062c', 'name': 'Algerian dinar' },
        { 'cc': 'EEK', 'symbol': 'KR', 'name': 'Estonian kroon' },
        { 'cc': 'EGP', 'symbol': '\u00a3', 'name': 'Egyptian pound' },
        { 'cc': 'ERN', 'symbol': 'Nfa', 'name': 'Eritrean nakfa' },
        { 'cc': 'ETB', 'symbol': 'Br', 'name': 'Ethiopian birr' },
        { 'cc': 'EUR', 'symbol': '\u20ac', 'name': 'European Euro' },
        { 'cc': 'FJD', 'symbol': 'FJ$', 'name': 'Fijian dollar' },
        { 'cc': 'FKP', 'symbol': '\u00a3', 'name': 'Falkland Islands pound' },
        { 'cc': 'GBP', 'symbol': '\u00a3', 'name': 'British pound' },
        { 'cc': 'GEL', 'symbol': 'GEL', 'name': 'Georgian lari' },
        { 'cc': 'GHS', 'symbol': 'GH\u20b5', 'name': 'Ghanaian cedi' },
        { 'cc': 'GIP', 'symbol': '\u00a3', 'name': 'Gibraltar pound' },
        { 'cc': 'GMD', 'symbol': 'D', 'name': 'Gambian dalasi' },
        { 'cc': 'GNF', 'symbol': 'FG', 'name': 'Guinean franc' },
        { 'cc': 'GQE', 'symbol': 'CFA', 'name': 'Central African CFA franc' },
        { 'cc': 'GTQ', 'symbol': 'Q', 'name': 'Guatemalan quetzal' },
        { 'cc': 'GYD', 'symbol': 'GY$', 'name': 'Guyanese dollar' },
        { 'cc': 'HKD', 'symbol': 'HK$', 'name': 'Hong Kong dollar' },
        { 'cc': 'HNL', 'symbol': 'L', 'name': 'Honduran lempira' },
        { 'cc': 'HRK', 'symbol': 'kn', 'name': 'Croatian kuna' },
        { 'cc': 'HTG', 'symbol': 'G', 'name': 'Haitian gourde' },
        { 'cc': 'HUF', 'symbol': 'Ft', 'name': 'Hungarian forint' },
        { 'cc': 'IDR', 'symbol': 'Rp', 'name': 'Indonesian rupiah' },
        { 'cc': 'ILS', 'symbol': '\u20aa', 'name': 'Israeli new sheqel' },
        { 'cc': 'INR', 'symbol': '\u20B9', 'name': 'Indian rupee' },
        { 'cc': 'IQD', 'symbol': '\u062f.\u0639', 'name': 'Iraqi dinar' },
        { 'cc': 'IRR', 'symbol': 'IRR', 'name': 'Iranian rial' },
        { 'cc': 'ISK', 'symbol': 'kr', 'name': 'Icelandic kr\u00f3na' },
        { 'cc': 'JMD', 'symbol': 'J$', 'name': 'Jamaican dollar' },
        { 'cc': 'JOD', 'symbol': 'JOD', 'name': 'Jordanian dinar' },
        { 'cc': 'JPY', 'symbol': '\u00a5', 'name': 'Japanese yen' },
        { 'cc': 'KES', 'symbol': 'KSh', 'name': 'Kenyan shilling' },
        { 'cc': 'KGS', 'symbol': '\u0441\u043e\u043c', 'name': 'Kyrgyzstani som' },
        { 'cc': 'KHR', 'symbol': '\u17db', 'name': 'Cambodian riel' },
        { 'cc': 'KMF', 'symbol': 'KMF', 'name': 'Comorian franc' },
        { 'cc': 'KPW', 'symbol': 'W', 'name': 'North Korean won' },
        { 'cc': 'KRW', 'symbol': 'W', 'name': 'South Korean won' },
        { 'cc': 'KWD', 'symbol': 'KWD', 'name': 'Kuwaiti dinar' },
        { 'cc': 'KYD', 'symbol': 'KY$', 'name': 'Cayman Islands dollar' },
        { 'cc': 'KZT', 'symbol': 'T', 'name': 'Kazakhstani tenge' },
        { 'cc': 'LAK', 'symbol': 'KN', 'name': 'Lao kip' },
        { 'cc': 'LBP', 'symbol': '\u00a3', 'name': 'Lebanese lira' },
        { 'cc': 'LKR', 'symbol': 'Rs', 'name': 'Sri Lankan rupee' },
        { 'cc': 'LRD', 'symbol': 'L$', 'name': 'Liberian dollar' },
        { 'cc': 'LSL', 'symbol': 'M', 'name': 'Lesotho loti' },
        { 'cc': 'LTL', 'symbol': 'Lt', 'name': 'Lithuanian litas' },
        { 'cc': 'LVL', 'symbol': 'Ls', 'name': 'Latvian lats' },
        { 'cc': 'LYD', 'symbol': 'LD', 'name': 'Libyan dinar' },
        { 'cc': 'MAD', 'symbol': 'MAD', 'name': 'Moroccan dirham' },
        { 'cc': 'MDL', 'symbol': 'MDL', 'name': 'Moldovan leu' },
        { 'cc': 'MGA', 'symbol': 'FMG', 'name': 'Malagasy ariary' },
        { 'cc': 'MKD', 'symbol': 'MKD', 'name': 'Macedonian denar' },
        { 'cc': 'MMK', 'symbol': 'K', 'name': 'Myanma kyat' },
        { 'cc': 'MNT', 'symbol': '\u20ae', 'name': 'Mongolian tugrik' },
        { 'cc': 'MOP', 'symbol': 'P', 'name': 'Macanese pataca' },
        { 'cc': 'MRO', 'symbol': 'UM', 'name': 'Mauritanian ouguiya' },
        { 'cc': 'MUR', 'symbol': 'Rs', 'name': 'Mauritian rupee' },
        { 'cc': 'MVR', 'symbol': 'Rf', 'name': 'Maldivian rufiyaa' },
        { 'cc': 'MWK', 'symbol': 'MK', 'name': 'Malawian kwacha' },
        { 'cc': 'MXN', 'symbol': '$', 'name': 'Mexican peso' },
        { 'cc': 'MYR', 'symbol': 'RM', 'name': 'Malaysian ringgit' },
        { 'cc': 'MZM', 'symbol': 'MTn', 'name': 'Mozambican metical' },
        { 'cc': 'NAD', 'symbol': 'N$', 'name': 'Namibian dollar' },
        { 'cc': 'NGN', 'symbol': '\u20a6', 'name': 'Nigerian naira' },
        { 'cc': 'NIO', 'symbol': 'C$', 'name': 'Nicaraguan c\u00f3rdoba' },
        { 'cc': 'NOK', 'symbol': 'kr', 'name': 'Norwegian krone' },
        { 'cc': 'NPR', 'symbol': 'NRs', 'name': 'Nepalese rupee' },
        { 'cc': 'NZD', 'symbol': 'NZ$', 'name': 'New Zealand dollar' },
        { 'cc': 'OMR', 'symbol': 'OMR', 'name': 'Omani rial' },
        { 'cc': 'PAB', 'symbol': 'B./', 'name': 'Panamanian balboa' },
        { 'cc': 'PEN', 'symbol': 'S/.', 'name': 'Peruvian nuevo sol' },
        { 'cc': 'PGK', 'symbol': 'K', 'name': 'Papua New Guinean kina' },
        { 'cc': 'PHP', 'symbol': '\u20b1', 'name': 'Philippine peso' },
        { 'cc': 'PKR', 'symbol': 'Rs.', 'name': 'Pakistani rupee' },
        { 'cc': 'PLN', 'symbol': 'z\u0142', 'name': 'Polish zloty' },
        { 'cc': 'PYG', 'symbol': '\u20b2', 'name': 'Paraguayan guarani' },
        { 'cc': 'QAR', 'symbol': 'QR', 'name': 'Qatari riyal' },
        { 'cc': 'RON', 'symbol': 'L', 'name': 'Romanian leu' },
        { 'cc': 'RSD', 'symbol': 'din.', 'name': 'Serbian dinar' },
        { 'cc': 'RUB', 'symbol': 'R', 'name': 'Russian ruble' },
        { 'cc': 'SAR', 'symbol': 'SR', 'name': 'Saudi riyal' },
        { 'cc': 'SBD', 'symbol': 'SI$', 'name': 'Solomon Islands dollar' },
        { 'cc': 'SCR', 'symbol': 'SR', 'name': 'Seychellois rupee' },
        { 'cc': 'SDG', 'symbol': 'SDG', 'name': 'Sudanese pound' },
        { 'cc': 'SEK', 'symbol': 'kr', 'name': 'Swedish krona' },
        { 'cc': 'SGD', 'symbol': 'S$', 'name': 'Singapore dollar' },
        { 'cc': 'SHP', 'symbol': '\u00a3', 'name': 'Saint Helena pound' },
        { 'cc': 'SLL', 'symbol': 'Le', 'name': 'Sierra Leonean leone' },
        { 'cc': 'SOS', 'symbol': 'Sh.', 'name': 'Somali shilling' },
        { 'cc': 'SRD', 'symbol': '$', 'name': 'Surinamese dollar' },
        { 'cc': 'SYP', 'symbol': 'LS', 'name': 'Syrian pound' },
        { 'cc': 'SZL', 'symbol': 'E', 'name': 'Swazi lilangeni' },
        { 'cc': 'THB', 'symbol': '\u0e3f', 'name': 'Thai baht' },
        { 'cc': 'TJS', 'symbol': 'TJS', 'name': 'Tajikistani somoni' },
        { 'cc': 'TMT', 'symbol': 'm', 'name': 'Turkmen manat' },
        { 'cc': 'TND', 'symbol': 'DT', 'name': 'Tunisian dinar' },
        { 'cc': 'TRY', 'symbol': 'TRY', 'name': 'Turkish new lira' },
        { 'cc': 'TTD', 'symbol': 'TT$', 'name': 'Trinidad and Tobago dollar' },
        { 'cc': 'TWD', 'symbol': 'NT$', 'name': 'New Taiwan dollar' },
        { 'cc': 'TZS', 'symbol': 'TZS', 'name': 'Tanzanian shilling' },
        { 'cc': 'UAH', 'symbol': 'UAH', 'name': 'Ukrainian hryvnia' },
        { 'cc': 'UGX', 'symbol': 'USh', 'name': 'Ugandan shilling' },
        { 'cc': 'USD', 'symbol': 'US$', 'name': 'United States dollar' },
        { 'cc': 'UYU', 'symbol': '$U', 'name': 'Uruguayan peso' },
        { 'cc': 'UZS', 'symbol': 'UZS', 'name': 'Uzbekistani som' },
        { 'cc': 'VEB', 'symbol': 'Bs', 'name': 'Venezuelan bolivar' },
        { 'cc': 'VND', 'symbol': '\u20ab', 'name': 'Vietnamese dong' },
        { 'cc': 'VUV', 'symbol': 'VT', 'name': 'Vanuatu vatu' },
        { 'cc': 'WST', 'symbol': 'WS$', 'name': 'Samoan tala' },
        { 'cc': 'XAF', 'symbol': 'CFA', 'name': 'Central African CFA franc' },
        { 'cc': 'XCD', 'symbol': 'EC$', 'name': 'East Caribbean dollar' },
        { 'cc': 'XDR', 'symbol': 'SDR', 'name': 'Special Drawing Rights' },
        { 'cc': 'XOF', 'symbol': 'CFA', 'name': 'West African CFA franc' },
        { 'cc': 'XPF', 'symbol': 'F', 'name': 'CFP franc' },
        { 'cc': 'YER', 'symbol': 'YER', 'name': 'Yemeni rial' },
        { 'cc': 'ZAR', 'symbol': 'R', 'name': 'South African rand' },
        { 'cc': 'ZMK', 'symbol': 'ZK', 'name': 'Zambian kwacha' },
        { 'cc': 'ZWR', 'symbol': 'Z$', 'name': 'Zimbabwean dollar' }
      ]
    }
  },
  methods: {
    changeSymbol () {
      let currencyObject = this.currencies.find((item) => {
        return item.cc === this.model.currency
      })
      this.model.currencySymbol = currencyObject.symbol
    },
    saveCompany () {
      userSession.putFile(STORAGE_FILE, JSON.stringify(this.model), this.$ENCRYPT)

      this.$notify({
        message: 'Settings saved',
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'center',
        verticalAlign: 'bottom',
        type: 'success',
        timeout: 1500
      })
    },
    saveLogo (logo) {
      if (this.model.logo !== '@/assets/img/company.png') {
        userSession.deleteFile(this.model.logo).then(() => {
          // file was deleted
          this.$notify({
            message: 'File uploaded',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'success',
            timeout: 1500
          })
        }).catch(() => {
          // file does not exist or is inaccessable
          this.$notify({
            message: 'Failed to delete the file',
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'bottom',
            type: 'danger',
            timeout: 1500
          })
        })
      }
      this.model.logo = logo
      this.saveCompany()
    },
    fetchData () {
      userSession.getFile(STORAGE_FILE, this.$DECRYPT).then((company) => {
        if (company) {
          this.model = JSON.parse(company)
        }
        setTimeout(() => { this.loadingPage = false }, 500)
      })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
